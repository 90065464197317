<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Users</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Users</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="checkPermit('CAN_CREATE_USER')"
                        :to="{name: 'create-user'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create User
                    </router-link>
                </b-col>
                <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type Email to Search"
                                v-on:keyup.enter="onFiltered"
                                @search="clearSearch"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link
                            class="circle"
                            v-b-tooltip.hover title="Edit"
                            :to="{ name: 'edit-user', params: { id:  row.item.id}}"
                        >
                            <font-awesome-icon
                                v-if="checkPermit('CAN_UPDATE_USER')"
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                        <span v-b-tooltip.hover title="Delete" v-if="checkPermit('CAN_DELETE_USER')" @click="info(row.item, row.index, $event.target)" class="text-danger circle ml-1">
                            <font-awesome-icon
                                icon="trash-alt"
                                v-b-modal.modal-center
                            ></font-awesome-icon>
                        </span>
                    </span>
                </template>
            </b-table>

            <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>

            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteUser(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10
            },
            success: false,
            updated: false,
            deleted: "",
            api_error: "",
            sortBy: "first_name",
            sortDesc: false,
            fields: [
                { key: "email", sortable: true },
                {
                    key: "first_name",
                    sortable: true,
                    formatter: value => {
                        return value.charAt(0).toUpperCase() + value.slice(1);
                    }
                },
                {
                    key: "last_name",
                    sortable: true,
                    formatter: value => {
                        return value != null
                            ? value.charAt(0).toUpperCase() + value.slice(1)
                            : "";
                    }
                },
                { key: "actions", label: "Actions" }
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_LIST_USERS");
        this.getUsers();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.first_name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered() {
            this.startProgressBar();
            this.urlBody.query = this.filter;
            this.urlBody.page = 1;
            var query = this.getAuthHeaders();
            query["params"] = this.urlBody;
            this.axios
                .get(this.$api.get_users, query)
                .then(response => {
                    const result = response.data.data[0];
                    this.items = result.data;
                    this.lastPage = result.last_page;
                    this.updateProgressBar(true);
                    this.deleted = "";
                    this.api_error = "";
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(this.urlBody)
                        })
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        getUsers: function() {
            this.startProgressBar();
            var urlBody =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody);
            var query = this.getAuthHeaders();
            query["params"] = urlBody;
            this.axios
                .get(this.$api.get_users, query)
                .then(response => {
                    const result = response.data.data[0];
                    this.items = result.data;
                    this.$route.query.page = this.urlBody.page =
                        result.current_page;
                    this.$route.query.per_page = this.urlBody.per_page = result.per_page;
                    this.lastPage = result.last_page;
                    this.totalRows = result.total;
                    this.deleted = "";
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(this.urlBody)
                        }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        deleteUser: function(user) {
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.delete_user,
                    JSON.parse(user),
                    this.getAuthHeaders()
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.deleted = "User Deleted Successfully.";
                        this.getUsers();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },
        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
        customSorting: function(ctx) {
            this.startProgressBar();
            this.urlBody.order = ctx.sortDesc ? "asc" : "desc";
            this.urlBody.sort_by = ctx.sortBy;
            this.urlBody.page = 1;
            var query = this.getAuthHeaders();
            query["params"] = this.urlBody;
            this.axios
                .get(this.$api.get_users, query)
                .then(response => {
                    const result = response.data.data[0];
                    this.items = result.data;
                    this.lastPage = result.last_page;
                    this.totalRows = result.total;
                    this.deleted = "";
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(this.urlBody)
                        })
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        checkPermit: function(permission) {
            return this.hasPermission(permission);
        },
        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.deleted = "";
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: newQuery
                })
                .catch(err => {
                    console.error(err);
                });
            this.getUsers();
        },
        clearSearch (event) {
            if (event.target.value === ""){
                if(this.urlBody.query != ''){
                    this.urlBody.query = '';
                    var newQuery = this.deleteEmptyKeys(this.urlBody);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: newQuery
                        })
                    this.getUsers()
                }
            }
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.deleted = "";
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: newQuery
                })
                .catch(err => {
                    console.error(err);
                });
            this.getUsers();
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>